import styles from "../../styles/about.module.scss"
import React, { useState } from "react"
import Layout from "../../components/layout/layout"
import LinkedinSvg from "../../components/svg/linkedin"
import SEO from "../../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import useWindowSize from "../../hooks/use-window-dimensions"
import withAppContext from "../../contexts/app.context"
import { Helmet } from "react-helmet"

const leadersData = [
  {
    name: "Ankur Singla",
    img_name: "ankur-singla.png",
    position: "Founder & CEO",
    linkedin_url: "https://www.linkedin.com/in/ankur-singla-4863801",
    bio:
      "Ankur is the founder and CEO of Volterra. Previously, he was the founder and CEO of Contrail Systems, which pioneered telco NFV and SDN technologies and was acquired by Juniper Networks in 2012. Contrail is the most widely deployed networking platform in Tier 1 telco mobile networks (AT&T, DT, Orange, NTT and Reliance JIO), and is used in many SaaS providers’ cloud deployments (Workday, Volkswagen, DirecTV). Prior to Contrail, Ankur was the CTO and VP Engineering at Aruba Networks, a global leader in wireless solutions. He holds an MS in Electrical Engineering from Stanford University and a BS in Electrical Engineering from the University of Southern California.",
  },
  {
    name: "Daniel Hua",
    img_name: "daniel-hua.png",
    position: "COO",
    linkedin_url: "https://www.linkedin.com/in/daniel-hua-491a65",
    bio:
      "Daniel is the COO of Volterra, responsible for all go-to-market activities. He has been an engineering and business leader at Fortune 500 companies for more than 30 years. Prior to Volterra, Daniel was SVP and GM of APAC at Juniper, where he rebuilt the theater business to achieve record revenue. He also served as SVP and GM of Juniper's Routing BU with responsibility for all routing products and solutions. Prior to Juniper, Daniel served as EVP for the Carrier Product Group for Alcatel Lucent in China and VP of Alcatel's IP Division for APAC. In this role, he built the APAC business from zero to second in the Edge Router market share. Daniel earned a Doctorate in Engineering from Yokohama National University in Japan and a BS from Shanghai Jiaotong University in China.",
  },
  {
    name: "Mark Weiner",
    img_name: "mark-weiner.png",
    position: "CMO",
    linkedin_url: "https://www.linkedin.com/in/mark-weiner-a912b5",
    bio:
      "Mark is CMO at Volterra and brings 30 years of experience in the IT infrastructure and cloud markets. He has served as CMO/VP Marketing at several successful startups including Versa Networks, cloud storage vendor StorSimple (acquired by Microsoft), identity vendor Centrify (acquired by Thoma Bravo), cloud network provider Virtela (acquired by NTT), and application delivery pioneer NetScaler (acquired by Citrix). Weiner also led marketing for $1 billion of data center products across multiple Cisco business units. Earlier in his career, he served as VP of Global Marketing at Redback Networks. Mark has an MBA from Santa Clara University, where he is a faculty member, and a BS from UC Berkeley's Haas Business School.",
  },
  {
    name: "Jun Shi",
    img_name: "jun-shi.png",
    position: "VP Sales & Business Development",
    linkedin_url: "https://www.linkedin.com/in/junshicn",
    bio:
      "Jun Shi is VP of Sales and Business Development for Volterra. He has over 20 years of experience in sales, product management, technical marketing and engineering. Before joining Volterra, he was VP of Sales Engineering at Juniper, where he reshaped the sales engineering function across APAC. Jun has also spent 10 years in product management growing market categories and flagship product lines at Juniper and Cisco. At Juniper, he transformed and grew the core router business to #1 (closed) market share in North America. At Cisco, he led the core router market transition and multiple hardware and software offerings. Jun has an MS in EE from Xidian University in China.",
  },
  {
    name: "Nuno Ferreira",
    img_name: "nuno-ferreira.png",
    position: "VP of Technology",
    linkedin_url: "https://www.linkedin.com/in/nunoferreira1",
    bio:
      "Nuno Ferreira is VP of Technology at Volterra, where he is focused on creating technology to address the Web 2.0 to 3.0 transition and the power of edge computing. Previously, Nuno was the Multi-Cloud Chief Architect at Juniper, where he was responsible for the company’s multi-cloud architectures and associated products. Nuno also led architecture and technical innovation for Juniper’s financial services customers and served as CTO of Financial Services. he has authored multiple books, publications and patents, and founded his first company Netopia at the age of 17. Nuno earned a Masters in Informatics Engineering from IST University of Lisbon.",
  },
  {
    name: "Harshad Nakil",
    img_name: "harshad-nakil.png",
    position: "Co-founder & CTO",
    linkedin_url: "https://www.linkedin.com/in/harshad-nakil-aba2b52",
    bio:
      "Harshad Nakil is co-founder and CTO of Volterra and a serial entrepreneur and innovator. Prior to starting Volterra, Harshad was a distinguished engineer at Juniper for several years following the acquisition of Contrail Systems, which he also co-founded and served as CTO. Before Contrail, he held senior engineering roles including technical lead and Fellow at Aruba Networks and Distinguished Engineer at Juniper. Earlier in his career, he was a technical lead at Cisco and software engineer at Wipro. Harshad has a degree in computer engineering from K. K. Wagh Institute Of Engineering in India.",
  },
  {
    name: "Raphaël Maunier",
    img_name: "raphael-maunier.png",
    position: "CTO of Infrastructure",
    linkedin_url: "https://www.linkedin.com/in/rmaunier",
    bio:
      "Raphaël Maunier is CTO of Infrastructure at Volterra. He has been working in the telecommunications industry since 1998. Prior to Volterra, Raphaël co-founded Acorus Networks and grew the business to dozens of customers across France and Europe. Previously, he was a technical leader at NeoTelecoms, a rapidly growing IP and Internet service provider in France. Through multiple engineering roles and later as CTO, he expanded NeoTelecoms’ backbone to more than a dozen countries and helped grow the company to become number two IP provider in France. He also managed a consulting company, Zoreole Services, which supported leading companies in their network and security strategies (AXA, Netflix, GE Software, etc).",
  },
  {
    name: "Marco Rodrigues",
    img_name: "marco-rodrigues.png",
    position: "VP of Products & Solutions",
    linkedin_url: "https://www.linkedin.com/in/marcorodrigues1",
    bio:
      "Marco is VP of Products and Solutions at Volterra. He brings deep experience in networking, telco cloud architecture and applications. Prior to Volterra, Marco was VP of Solutions Architecture at Juniper and managed the architecture, design and deployment of clouds for AT&T Mobility, Deutsche Telekom, GE, Workday and other global companies. In his role as Distinguished Engineer, Chief Architect for the AT&T account team, he worked with AT&T Labs and innovation teams to define the architecture of the AT&T Integrated cloud, global backbone and enterprise connectivity services enabling Fortune 100 companies, millions of consumers and US federal and state governments. He has a BS in Telecommunications from Sheridan College in Canada.",
  },
  {
    name: "Benjamin Schilz",
    img_name: "benjamin-schilz.png",
    position: "VP of Infrastructure",
    linkedin_url: "https://www.linkedin.com/in/benjaminschilz",
    bio:
      "Benjamin Schilz is VP of Infrastructure at Volterra and responsible for the operation of the company’s global resources and network. Benjamin has been an Internet entrepreneur for over 15 years, building multiple companies and sets of innovation in security and networking. He co-founded Acorus Network to provide secure connectivity and denial of service (DDoS) protection to enterprise and Internet companies across Europe, and served as its CEO. Previously, Benjamin founded his first company, Easy-Hebergement, in 2003 while still in college. He grew the company to 7,000 clients and more than 17,000 hosted sites before merging it into the Mediactive Group. Benjamin has a Bachelors from Telecom Lille.",
  },
]

const AboutPage = () => {
  const queryResult = useStaticQuery(graphql`
    {
      seo: markdownRemark(
        fields: {
          relativePath: { eq: "company__about" }
          category: { eq: "seo" }
        }
      ) {
        frontmatter {
          metaTitle
          metaDescription
          metaKeywordList
          metaImageName
        }
      }
    }
  `)

  const {
    seo: {
      frontmatter: {
        metaTitle,
        metaDescription,
        metaKeywordList,
        metaImageName,
      },
    },
  } = queryResult

  const [currentInvestor, setCurrentInvestor] = useState(null)
  const { width } = useWindowSize()

  let showWide = false
  let showNormal = false

  if (!!width) {
    if (width <= 575) {
      showNormal = true
      showWide = false
    } else if (width <= 768) {
      showNormal = false
      showWide = true
    } else if (width <= 992) {
      showNormal = true
      showWide = false
    } else if (width <= 1200) {
      showNormal = false
      showWide = true
    } else if (width > 992) {
      showNormal = true
      showWide = false
    }
  }

  return (
    <Layout>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
                "@context": "http://schema.org",
                "@type": "Corporation",
                "additionalType":"http://www.productontology.org/doc/Platform_as_a_service",
                "@id":"https://www.volterra.io/",
                "legalName": "Volterra Inc",
                "description": "Volterra offers a distributed cloud platform to deploy, connect, secure, observe and operate apps and data globally across multiple clouds and the edge",
                "url":"https://www.volterra.io/",
                "address": {
                  "@type": "PostalAddress",
                  "addressLocality": "San Jose, California, USA",
                  "postalCode": "CA 95134",
                  "streetAddress": "3545 N 1st St"
                },
                "email": "sales@volterra.io",
                "name": "Volterra Inc",
                "telephone": "+1 (408) 900-6788",
                "logo":"https://www.volterra.io/img/main/logo.svg",
                "image":"https://www.volterra.io/img/main/apps.png",
                "sameAs" :
                  [
                    "https://twitter.com/volterra_",
                    "https://www.linkedin.com/company/volterra-edge-services/",
                    "https://www.crunchbase.com/organization/volterra-inc"
                  ],
  
                "funder":
                  [
                    {
                      "@type": "Corporation",
                      "legalName": "Mayfield",
                      "url":"https://www.mayfield.com/",
                      "sameAs": "https://en.wikipedia.org/wiki/Mayfield_(company)"
                    },
                    {
                      "@type": "Corporation",
                      "legalName": "Khosla Ventures",
                      "url":"https://www.khoslaventures.com/",
                      "sameAs": "https://en.wikipedia.org/wiki/Khosla_Ventures"
                    },
                    {
                      "@type": "Corporation",
                      "legalName": "(M12) Microsoftís venture fund",
                      "url":"https://m12.vc/",
                      "sameAs": "https://en.wikipedia.org/wiki/M12_(venture_capital)"
                    },
                    {
                      "@type": "Corporation",
                      "legalName": "Samsung NEXT",
                      "url":"https://samsungnext.com/",
                      "sameAs": "https://www.crunchbase.com/organization/samsungnext"
                    },
                    {
                      "@type": "Corporation",
                      "legalName": "Itochu Technology Ventures",
                      "url":"https://www.techv.co.jp/en/",
                      "sameAs": "https://www.crunchbase.com/organization/itochu-technology-ventures"
                    }
                  ],
  
                "owns":
                  [
                    {
                      "@type":"Product",
                      "name":"VoltShare",
                      "url":"https://www.volterra.io/products/voltshare",
                      "image":"https://www.volterra.io/img/products/voltshare.png",
                      "description":"Securely share your critical data while ensuring compliance. Simplify end-to-end encryption. Make your existing collaboration tools more secure."
                    },
                    {
                      "@type":"Product",
                      "name":"VoltMesh",
                      "url":"https://www.volterra.io/products/voltmesh",
                      "image":"https://www.volterra.io/img/products/voltmesh.png",
                      "description":"A SaaS-based offering that delivers high-performance networking and zero-trust security across clouds and edge sites along with the ability to offload apps on our global network"
                    },
                    {
                      "@type":"Product",
                      "name":"VoltStack",
                      "url":"https://www.volterra.io/products/voltstack",
                      "image":"https://www.volterra.io/img/products/voltstack.png",
                      "description":"SaaS-based offering that automates deployment, security and operations of distributed apps and infrastructure across the edge, public/private clouds and our global application delivery network"
                    },
                    {
                      "@type":"Product",
                      "name":"VoltConsole",
                      "url":"https://www.volterra.io/products/voltconsole",
                      "image":"https://www.volterra.io/img/products/voltconsole.png",
                      "description":"A SaaS-based operations and observability portal to manage infrastructure and apps across multi-cloud and edge, provide early warnings and generate actionable business insights"
                    },
                    {
                      "@type":"Product",
                      "name":"Volterra Global Network",
                      "url":"https://www.volterra.io/products/volterra-global-network",
                      "image":"https://www.volterra.io/img/products/global-network.png",
                      "description":"Industry's first app-to-app network and security service that's purpose built to connect and secure your global workloads across multi-cloud and edge"
                    }
                  ]
              }
          `}
        </script>
      </Helmet>

      <SEO
        title={metaTitle}
        pageUrl={"/company/about"}
        description={metaDescription}
        keywords={metaKeywordList}
        imageName={!!metaImageName ? `/previews/${metaImageName}` : undefined}
      />
      <div className="about-container">
        <section className={styles.header}>
          <div className="container volterra-container">
            <div className="row">
              <h1 className={styles.header__title}>
                Enable a Distributed Cloud for <br />
                Everyone, Everywhere.
              </h1>
            </div>
          </div>
        </section>

        <section className={styles.leadership}>
          <div className={`container ${styles.leadership__container}`}>
            <div className="row">
              <div className={styles.mission}>
                <h5 className={styles.mission__title}>Our Mission</h5>
                <p className={styles.mission__description}>
                  “We believe that cloud services are going to be the
                  “dial-tone” of every modern organization and we are determined
                  to deliver “universal cloud access”. From the inception of the
                  company, we have been innovating on a broad set of cloud
                  services that give you the flexibility to build your own
                  distributed cloud across multiple cloud providers or your own
                  edge locations.”
                </p>
              </div>
              <div
                className={styles.principles}
                ves-e2e-test="about-us/principles-section">
                <h5 className={styles.principles__title}>Principles</h5>
                <ul className={`${styles.principles__list} row`}>
                  <li className={`${styles.principles__item} col-12 col-lg-6`}>
                    <div>Focus on customers, not competitors</div>
                  </li>
                  <li className={`${styles.principles__item} col-12 col-lg-6`}>
                    <div>Embrace change and no technology religion</div>
                  </li>
                  <li className={`${styles.principles__item} col-12 col-lg-6`}>
                    <div>Continuous improvement</div>
                  </li>
                  <li className={`${styles.principles__item} col-12 col-lg-6`}>
                    <div>Openness and transparency</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section
          className={`container ${styles.investors}`}
          ves-e2e-test="about-us/investors-section">
          <div className="row justify-content-center">
            <h2 className={styles.investors__header}>Industry Recognition</h2>
          </div>
          <div className={styles.recognitiongrid}>
            <div>
              <img
                src="/img/company/recognition/gartner-cool-vendor2020.jpg"
                width="400"
                height="400"
                alt="Gartner Cool Vendor 2020"
              />
            </div>
            <div>
              <img
                src="/img/company/recognition/interop-best-of-show-2020.jpg"
                width="400"
                height="400"
                alt="Interop Best of Show Award 2020"
              />
            </div>
            <div>
              <img
                src="/img/company/recognition/idc-innovator.jpg"
                width="400"
                height="400"
                alt="IDC Innovator"
              />
            </div>
            <div>
              <img
                src="/img/company/recognition/frost-sullivan-best-practices-award-2020.jpg"
                width="400"
                height="400"
                alt="Frost & Sullivan Best Practices Award 2020"
              />
            </div>
            <div>
              <img
                src="/img/company/recognition/crn-emerging-vendors-2020.jpg"
                width="400"
                height="400"
                alt="The Channel Co Emerging Vendors 2020"
              />
            </div>
            <div>
              <img
                src="/img/company/recognition/stratus-award-2020.jpg"
                width="400"
                height="400"
                alt="Stratus Award 2020"
              />
            </div>
            <div>
              <img
                src="/img/company/recognition/cloud-computing-security-excellence-2020.jpg"
                width="400"
                height="300"
                alt="Cloud Computing Security Excellence 2020"
              />
            </div>
            <div>
              <img
                src="/img/company/recognition/it-world-awards-gold-2020.jpg"
                width="400"
                height="400"
                alt="IT World Awards Gold 2020"
              />
            </div>
            <div>
              <img
                src="/img/company/recognition/leading-lights-awards-finalist-2020.jpg"
                width="400"
                height="400"
                alt="Leading Lights Awards Finalist 2020"
              />
            </div>
            <div>
              <img
                src="/img/company/recognition/the-cloud-awards-finalist-2020-21.jpg"
                width="400"
                height="400"
                alt="The Cloud Awards Finalist 2020-21"
              />
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default withAppContext(AboutPage)
