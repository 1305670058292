import { useEffect, useState } from "react"

const useWindowSize = () => {
  const windowExists = typeof window !== "undefined"

  const getWindowSize = () => {
    const width = windowExists ? window.innerWidth : null
    const height = windowExists ? window.innerHeight : null
    return {
      width,
      height,
    }
  }

  const [windowSize, setWindowSize] = useState(getWindowSize())

  useEffect(() => {
    if (windowExists) {
      const handleResize = () => {
        setWindowSize(getWindowSize())
      }

      window.addEventListener("resize", handleResize)
      return () => window.removeEventListener("resize", handleResize)
    }
  }, [windowExists])

  return windowSize
}

export default useWindowSize
